import React from 'react'
import NavItem from './NavItem'
import './StyleNavbar.css'
export default function Navbar() {
  return (
    <div className='NavBarCont'>
        {/* <NavItem text="Biografía" link="/bio" /> */}
        <NavItem text="Home" link="/" />
        <NavItem text="Trabajos" link="/trabajos" />
        <NavItem text="Tu web de turnos online" link="/turnos" />
    </div>
  )
}
