import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../navbar/Navbar'
import ProfileWidget from '../widgets/ProfileWidget';
import './StyleWorks.css'
export default function TrabajosCard({ id, image, tittle, description, cat, catImg, link }) {



    return (

        <Link target='_blank' to={link} className='ShopCardCont' >

            <img className="ShopCardImg" src={image} alt="" />

            <div className='ShopCardTextCont'>
                <div className='rowIcons'>
                    <img className='catCardImg' src={catImg} alt="" />
                    <p className='catCardText'> {cat} </p>
                </div>

                <p className='ShopCardTittle'> {tittle} </p>
                <p className='ShopCardText'> {description} </p>

                {/* <div className='rowIcons'>

                </div> */}
            </div>




        </Link>
    )
}
