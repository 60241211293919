import React from 'react'

export default function TurnosItem ( { titulo, descripcion, img}) {
  return (
    <div className='turnosItemCont'>
        <img className='turnosItemImg' src={img} alt="" />
        <p className='turnosItemTittle'> {titulo} </p>
        <p className='turnosItemDescription'> {descripcion} </p>
    </div>
  )
}
