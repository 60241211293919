import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    

    <footer className='footer'>
    <Link to="https://api.whatsapp.com/send/?phone=5491132683423&text&type=phone_number&app_absent=0">
        <img className='footerImg' src="https://img.icons8.com/ios/100/null/whatsapp--v1.png" />
    </Link>

    <Link to="https://www.linkedin.com/in/tom%C3%A1s-torres-48520599/">
        <img className='footerImg' src="https://img.icons8.com/ios/100/null/linkedin-2--v1.png" />
    </Link>

</footer>

  )
}
