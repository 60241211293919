import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../navbar/Navbar'
import './widgets.css'

export default function ProfileWidget() {
  const [biobarOpen, setBiobarOpen] = useState(false)
  const [biobarOpenTexts, setBiobarOpenTexts] = useState(false)

  const openBar = () => {
    if (biobarOpen === false) {
      setBiobarOpen(true)
      setTimeout(() => {
        setBiobarOpenTexts(true)
      }, 200);
    } else {
      setBiobarOpen(false)
      setTimeout(() => {
        setBiobarOpenTexts(false)
      }, 600);

    }

  }

  return (
    <>
      <img onClick={openBar} className='profilePic' src="https://pbs.twimg.com/profile_images/1616643966669127680/p8zmayJY_400x400.jpg" alt="" />

      <div className={biobarOpen === true ? 'bioBarOpen' : 'bioBar'}>
        {biobarOpenTexts === false ? <></> :

          <>
            <p className='bioBarText'>Tomás Torres</p>
            <p className='bioBarText'>Argentino</p>
            <p className='bioBarText'>30 años</p>
            <p className='bioBarText'>Front-End Dev</p>
            <p className='bioBarText'>Periodista</p>
            <p className='bioBarText'>Community Manager</p>
            <div className='rowStack'>
              <img className='stackImgSmall' src="https://img.icons8.com/external-others-amoghdesign/96/null/external-react-native-soleicons-fill-vol-1-others-amoghdesign.png" />
              <img className='stackImgSmall' src="https://img.icons8.com/color/240/null/javascript--v1.png" />
              <img className='stackImgSmall' src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/96/null/external-html-5-is-a-software-solution-stack-that-defines-the-properties-and-behaviors-of-web-page-logo-color-tal-revivo.png" />
              <img className='stackImgSmall' src="https://img.icons8.com/color/240/null/firebase.png" />
            </div>

            <div className='rowStack margintop0'>
              <img className='stackImgSmall' src="https://img.icons8.com/color/240/null/sass-avatar.png" />
              <img className='stackImgSmall' src="https://img.icons8.com/material-outlined/96/null/github.png" />
              <img className='stackImgSmall' src="https://img.icons8.com/color/240/null/adobe-photoshop--v1.png" />
              <img className='stackImgSmall' src="https://img.icons8.com/color/240/null/adobe-premiere-pro--v1.png" />
            </div>

          </>}

      </div>
    </>
  )
}
