import React from 'react'
import { Link } from 'react-router-dom';

export default function NavItem({text, link}) {
  return (
    <Link className='navBarItem' to={link}>
        {text}
    </Link>
  )
}
