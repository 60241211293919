import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Navbar from '../navbar/Navbar';
import TurnosItem from './TurnosItem';
import { Link } from 'react-router-dom';
export default function Turnos() {

  const trabajos = [
    {
      "id": 1, "catImg": "https://img.icons8.com/ios-filled/100/null/barber-scissors.png", "cat": "peluquería & turnos online", "image": "https://firebasestorage.googleapis.com/v0/b/coqueteria-b7812.appspot.com/o/default%2Flogo.jpg?alt=media&token=8d13d404-2cbf-48d9-8e14-578f9f4f5b74", "tittle": "La Coquetería",
      "description": "Sitio de turnos online con administrador completo.", "link": "https://lacoqueteria.com.ar"
    } ,

    {
      "id": 2, "cat": "tienda & turnos online", "image": "https://firebasestorage.googleapis.com/v0/b/dondevestir-aff92.appspot.com/o/images%2Fddv.png?alt=media&token=e10f47af-7de7-4c90-8a43-26370f6ada09", "tittle": "Don de Vestir",
  "link": "https://dondevestir.com.ar"
    }

  ]

  const [data, setData] = useState(null);

  useEffect(() => {
    // Aquí realizamos la petición a la API
    fetch('https://www.dolarsi.com/api/api.php?type=valoresprincipales')
      .then(response => response.json())
      .then(data => {
        setData(data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

 let dolarPrice = data ? data.filter(e =>  e.casa.nombre === "Dolar Blue")[0].casa.venta.substring(0, 3) : 0
 const opciones = { style: 'currency', currency: 'ARS', minimumFractionDigits: 0, maximumFractionDigits: 0 };

  console.log(parseInt(dolarPrice))


  return (
    <div className='homepageCont'>


      <Navbar />

      <div className='trabajosCardListCont'>

        <div className='column'>

          <p className="turnosTittle"> 10 USD <br /> <span className='turnosTittleMini'>final por mes </span></p>
          <p className="ItemDescription"> *Más pago único de 50 USD: incluye 1 año de dominio y personalización del sitio.  </p>

        </div>
        <div className='column2'>
          <div className='rowOfItems'>
            <TurnosItem img="https://img.icons8.com/material-two-tone/96/null/domain--v1.png" titulo="Tu propio dominio" descripcion="Entrá a nic.ar, buscá tu dominio .com.ar o .ar y ¡listo! El costo del mismo está incluido en el paquete." />
            <TurnosItem img="https://img.icons8.com/ios-filled/100/null/edit--v1.png" titulo="Personalizalo" descripcion="Elegí las imágenes, los colores y las tipografías a tu gusto. También podés agregar textos y galerías de imágenes." />
            <TurnosItem img="https://img.icons8.com/ios-filled/100/null/calendar--v1.png" titulo="+ Agendas" descripcion="Podés configurar más de una agenda sin ningún costo extra y en cualquier momento que lo necesites." />
            <TurnosItem img="https://img.icons8.com/ios-filled/100/null/shop.png" titulo="Tienda" descripcion="Además, con un costo extra, podés tener tu propia tienda online 100% autoadministrable." />

            <TurnosItem img="https://cdn.icon-icons.com/icons2/3913/PNG/512/mercadopago_logo_icon_248494.png" titulo="Mercadopago" descripcion="Cobrá seña o la totalidad de los servicios a través de Mercadopago." />
            <TurnosItem img="https://img.icons8.com/ios-filled/100/null/paint.png" titulo="Sin límites" descripcion="El sitio tiene un diseño predeterminado, pero podemos hacerlo a tu gusto y agregar todas las secciones y funcionalidades que se ajusten a tu necesidad (consultar por precio)." />
            <TurnosItem img="https://img.icons8.com/fluency-systems-filled/96/null/technical-support-wearing-glasses.png" titulo="Soporte" descripcion="Te ayudaremos para que aprendas a usar el sitio y la cuota mensual incluye soporte de lunes a viernes." />

          </div>

          <div className='row'>
            <p className="turnosTittleMini">Clientes</p>
            {trabajos.map((e) =>
              <Link key={e.id} to={e.link}>
                <img className='sitiosweb' src={e.image} alt="" />
              </Link>
            )}
          </div>
        </div>
      </div>



      {/* BACKGROUND MOVEMENT */}
      <div className='ball1'></div>
      <div className='ball2'></div>
      <div className='ball3'></div>
      <div className='ball4'></div>
    </div>
  )
}
