import React, { useState, useEffect } from 'react';
import Footer from '../homepage/Footer';
import Navbar from '../navbar/Navbar'
import './StyleWorks.css'
import TrabajosCard from './TrabajosCard';

export default function TrabajosListCont() {

    const trabajos = [
        { "id": 1, "catImg": "https://img.icons8.com/ios-filled/50/ticket.png", "cat": "ticketing web app", "image": "https://firebasestorage.googleapis.com/v0/b/lascosasqueguardo-c32ef.appspot.com/o/Captura%20de%20pantalla%202024-02-19%20155201.png?alt=media&token=81611f3c-0242-4401-ba6b-9ff86efb98af",   "tittle": "Entradita", 
        "description": "Plataforma de ticketing y eventos.", "link": "https://entradita.com.ar/"},

        { "id": 2, "catImg": "https://img.icons8.com/ios-glyphs/90/long-formal-dress.png", "cat": "tienda & portfolio", "image": "https://firebasestorage.googleapis.com/v0/b/dondevestir-aff92.appspot.com/o/images%2Fddv.png?alt=media&token=e10f47af-7de7-4c90-8a43-26370f6ada09",   "tittle": "Don de vestir", 
        "description": "Atelier. Tienda online, portfolio y turnero online.", "link": "https://dondevestir.com.ar"},
,

        { "id": 3, "catImg": "https://img.icons8.com/ios-filled/100/null/barber-scissors.png", "cat": "peluquería & turnos online", "image": "https://firebasestorage.googleapis.com/v0/b/coqueteria-b7812.appspot.com/o/default%2Flogo.jpg?alt=media&token=8d13d404-2cbf-48d9-8e14-578f9f4f5b74",   "tittle": "La Coquetería", 
        "description": "Sitio de turnos online autoadministrable.", "link": "https://lacoqueteria.com.ar"},

        { "id": 4, 
        "catImg": "https://img.icons8.com/external-itim2101-lineal-itim2101/64/null/external-hotel-bell-travel-itim2101-lineal-itim2101.png", 
        "cat": "hotelería", 
        "image": "http://miradasdellago.com.ar/images/lahosteria2.webp", 
        "tittle": "Miradas del Lago", 
        "description": "Hostería ubicada en Epuyén, Chubut.",
        "link": "http://miradasdellago.com.ar/"
      },
      { "id": 5, 
      "catImg": "https://img.icons8.com/external-those-icons-fill-those-icons/96/null/external-app-applications-windows-those-icons-fill-those-icons-4.png", 
      "cat": "Web App", 
      "image": "https://firebasestorage.googleapis.com/v0/b/polacohaliasz.appspot.com/o/homepage%2FCaptura%20de%20pantalla%202023-03-17%20140914.png?alt=media&token=1135dad2-fd4a-4b7f-b4e8-3bbd2e6d0556", 
      "tittle": "Holde.AR", 
      "description": "Web App de administración hotelera.",
      "link": "https://holde.ar/"
    },
  { "id": 6, 
  "catImg": "https://img.icons8.com/ios-filled/100/null/wallmount-camera.png", 
  "cat": "Seguridad", 
  "image": "http://www.segurt.com.ar/images/01-portada.jpg", 
  "tittle": "Segurt", 
  "description": "Empresa de seguridad privada. Para Casa Kiev! Productora.",
  "link": "http://www.segurt.com.ar/"
},
{ "id": 7, 
"catImg": "https://img.icons8.com/ios-glyphs/90/null/parliament.png", 
"cat": "Institucional", 
"image": "https://firebasestorage.googleapis.com/v0/b/polacohaliasz.appspot.com/o/homepage%2FCaptura%20de%20pantalla%202023-03-17%20142600.png?alt=media&token=e88dc9fd-5b3f-45f5-bdc2-f1468e98ca41", 
"tittle": "GCBA", 
"description": "Micrositios y mailing para el programa Educación y Memoria, del Ministerio de Educación de la Ciudad."
}

    ]

    return (
        <div className='homepageCont'>

            <Navbar />
            <Footer/>

            <div className='trabajosCardListCont'>
                {trabajos.map((e) =>
                    <TrabajosCard key={e.id} {...e} />
                )}

            </div>

   

            {/* BACKGROUND MOVEMENT */}
            <div className='ball1'></div>
            <div className='ball2'></div>
            <div className='ball3'></div>
            <div className='ball4'></div>
        </div>
    )
}
