import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './homepage/Home';
import TrabajosListCont from './trabajos/TrabajosListCont';
import Turnos from './homepage/Turnos';


function App() {
  return (
<BrowserRouter>

      <div className='App' >
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/trabajos' element={<TrabajosListCont />} />
          <Route path='/turnos' element={<Turnos />} />
        </Routes>
      </div>

    </BrowserRouter>
  );
}

export default App;
