import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../navbar/Navbar'
import ProfileWidget from '../widgets/ProfileWidget';
import './Home.css'
import Loader from '../widgets/Loader';

export default function Home() {

    let tittle = "{tt}"
    return (
        <>
            <div className='homepageCont'>
                <Navbar />
                <p className='homeTittle'>{tittle}</p>
                <ProfileWidget />
                <footer className='footer'>
                    <Link to="https://api.whatsapp.com/send/?phone=5491132683423&text&type=phone_number&app_absent=0">
                        <img className='footerImg' src="https://img.icons8.com/ios/100/null/whatsapp--v1.png" />
                    </Link>
                    <Link to="https://www.linkedin.com/in/tom%C3%A1s-torres-48520599/">
                        <img className='footerImg' src="https://img.icons8.com/ios/100/null/linkedin-2--v1.png" />
                    </Link>
                </footer>
                <div className='ball1'></div>
                <div className='ball2'></div>
                <div className='ball3'></div>
                <div className='ball4'></div>
            </div>
        </>
    )
}
